import axios from 'axios';
import OpenAI from "openai";

// This function is hypothetical and should be executed in a server-side environment
// to keep your OpenAI API key secure.
async function analyzeComments(comments) {
  try {
    const openai = new OpenAI({
      apiKey: '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',
      dangerouslyAllowBrowser: true
    });

    const completion = await openai.chat.completions.create({
      messages: [{ "role": "system", "content": "You are a helpful assistant." },
      { "role": "user", "content": "This is what some people think of a video. Could you summarize their opinions? \n" + comments.join('\n') }],
      model: "gpt-3.5-turbo",
    });

    const summary = completion.choices[0].message.content;

    const completion1 = await openai.chat.completions.create({
      messages: [{ "role": "system", "content": "You are a helpful assistant." },
      {
        "role": "user", "content":
          "This is what some people think of a video. Could you give a rating of the video based on their opinions? \
          \n" + comments.join('\n') + "The rating scale is as follows: \
          S means this is among the best videos people have seen in their lives. \
          A means this is an amazing video, most people are extremely excited about it and have really positive things to say \
          B means it's a good video. People generally like it and give supportive opinions. \
          C means it's an alright video. Some people like it but there are more mixed reviews. \
          D means it's generally a bad video. Most people are complaining or at least a majority seem to have negative opinions. \
          F means it's a terrible video and people generally dislike it or have bad things to say. \
          Most importantly, please respond with the rating and just the rating in bold, either S, A, B, C, D, or F. Thanks!"
      }],
      model: "gpt-3.5-turbo",
    });

    const finalResult = {
      summary: summary,
      rating: completion1.choices[0].message.content
    };

    return finalResult;

  } catch (error) {
    return {
      summary: "",
      rating: ""
    }
    // console.error('Error analyzing comments with OpenAI:', error);
    // throw error; // Re-throw to handle it in the calling function
  }
}

function decodeHtmlEntities(str) {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = str;
  return textarea.value;
}

export async function fetchCommentsAndAnalyze(videoId) {
  const apiKey = 'AIzaSyDgIvGLR_s05UroGW5CgdftmOcQ85H30So';
  const commentsUrl = `https://www.googleapis.com/youtube/v3/commentThreads?part=snippet&videoId=${videoId}&key=${apiKey}&textFormat=plainText&maxResults=100`;

  try {
    // Fetch comments from YouTube
    const commentsResponse = await axios.get(commentsUrl);
    const comments = commentsResponse.data.items.map(item => item.snippet.topLevelComment.snippet.textDisplay);

    // Now analyze these comments with OpenAI (handled on your server)
    const analysisResult = await analyzeComments(comments);
    return analysisResult;
  } catch (error) {
    console.error('Error fetching comments or analyzing:', error);
    throw error;
  }
}

export async function fetchTranscript(videoId, lang = 'en') {
  try {
    // const response = await axios.get(`http://localhost:5001/api/transcript?videoId=${videoId}&lang=${lang}`);
    const response = await axios.get(`/api/transcript?videoId=${videoId}&lang=${lang}`);
    const random = [];

    const transcript = response.data;
    const transcriptText = decodeHtmlEntities(transcript.map((item) => item.text).join(' '));
    return transcriptText;
  } catch (error) {
    console.error('Error fetching transcript:', error);
    return "";
    return "Transcript analysis feature will be back soon.";
    throw error;
  }
}

function formatQAString(qaString) {
  const questions = [];
  const answers = [];

  // Extract questions part
  const questionsPart = qaString.match(/Comprehension Questions:\s*([\s\S]*?)\s*Answers:/);
  if (questionsPart && questionsPart[1]) {
    const questionList = questionsPart[1].trim().split(/\d\.\s/).filter(Boolean);
    questions.push(...questionList);
  }

  // Extract answers part
  const answersPart = qaString.match(/Answers:\s*([\s\S]*)/);
  if (answersPart && answersPart[1]) {
    const answerList = answersPart[1].trim().split(/\d\.\s/).filter(Boolean);
    answers.push(...answerList);
  }

  return {
    questions,
    answers
  };
}

async function analyzeTranscript(transcript) {
  try {
    const openai = new OpenAI({
      apiKey: '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',
      dangerouslyAllowBrowser: true
    });

    const chunkSize = 15000;
    const chunks = [];

    for (let i = 0; i < transcript.length; i += chunkSize) {
      chunks.push(transcript.substring(i, i + chunkSize));
      console.log(i);
    }

    const summaries = [];
    const allQuestions = [];
    const allAnswers = [];

    for (const chunk of chunks) {
      const completion = await openai.chat.completions.create({
        messages: [
          { "role": "system", "content": "You are a knowledgable teacher and helpful assistant." },
          { "role": "user", "content": "Please provide a paragraph summary of the following text, but don't mention \"the text\", just give the summary: \n" + chunk }
        ],
        model: "gpt-3.5-turbo",
      });

      const summary = completion.choices[0].message.content;
      summaries.push(summary);

      console.log(summary);

      let completionQuestions;
      if (chunks.length > 2) {
        completionQuestions = await openai.chat.completions.create({
          messages: [
            { "role": "system", "content": "You are a helpful assistant and knowledgable teacher." },
            {
              "role": "user", "content": "Following this message is a video of something educational. \
              Please provide 3 comprehension questions to test the user's comprehension of the video, followed by the answers to those questions. \
              Make the last question more in depth, where the student may have to think more deeply. \
              Your response should be in the format: \
              Comprehension Questions: \
              1. [question 1] \
              2. [question 2] \
              3. [question 3] \
              Answers: \
              1. [answer 1] \
              2. [answer 2] \
              3. [answer 3] \
              Here's the text: \
              \n" + chunk
            }
          ],
          model: "gpt-3.5-turbo",
        });
      }

      else {
        completionQuestions = await openai.chat.completions.create({
          messages: [
            { "role": "system", "content": "You are a helpful assistant." },
            {
              "role": "user", "content": "Following this message is a video, probably of something educational. \
              Please provide 5 comprehension questions to test the user's comprehension of the video, followed by the answers to those questions. \
              Make the last question more in depth, where the student may have to think more deeply. \
              Your response should be in the format: \
              Comprehension Questions: \
              1. [question 1] \
              2. [question 2] \
              3. [question 3] \
              4. [question 4] \
              5. [question 5] \
              Answers: \
              1. [answer 1] \
              2. [answer 2] \
              3. [answer 3] \
              4. [answer 4] \
              5. [answer 5] \
              Here's the text: \
              \n" + chunk
            }
          ],
          model: "gpt-3.5-turbo",
        });
      }

      const questionsRaw = completionQuestions.choices[0].message.content;
      const qAndA = formatQAString(questionsRaw);

      allQuestions.push(...qAndA.questions);
      allAnswers.push(...qAndA.answers);

      console.log(qAndA.questions);

      console.log(qAndA.answers);
    }

    // const combinedSummary = summaries.join('\n\n');
    const combinedSummary = summaries.join('\n\n').replace(/\n/g, '<br>');

    const result = {
      summary: combinedSummary,
      text: transcript,
      questions: allQuestions,
      answers: allAnswers,
    };

    return result;
  } catch (error) {
    console.error('Error analyzing transcript with OpenAI:', error);
    throw error;
  }
}


export async function fetchTranscriptAndAnalyze(videoId) {
  try {
    const transcriptText = await fetchTranscript(videoId, 'en');
    const analysisResult = await analyzeTranscript(transcriptText);
    return analysisResult;
  } catch (error) {
    console.error('Error fetching or analyzing transcript:', error);
    throw error;
  }
}

export async function getVideoDetails(videoId) {
  const apiKey = 'AIzaSyDgIvGLR_s05UroGW5CgdftmOcQ85H30So';
  const videoDetailsUrl = `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics&id=${videoId}&key=${apiKey}`;

  try {
    const response = await axios.get(videoDetailsUrl);
    const videoDetails = response.data.items[0];

    const details = {
      title: videoDetails.snippet.title,
      channel: videoDetails.snippet.channelTitle,
      description: videoDetails.snippet.description,
      thumbnail: videoDetails.snippet.thumbnails.default,
      viewCount: videoDetails.statistics.viewCount,
      likeCount: videoDetails.statistics.likeCount,
      commentCount: videoDetails.statistics.commentCount,
    };

    return details;
  } catch (error) {
    console.error('Error fetching video details:', error);
    throw error;
  }
}

function extractVideoId(url) {
  const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
  return match && match[1];
}