import React, { useState } from 'react';
import './TopBar.css';
import { logOut } from "../firebase";
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';

const TopBar = ({ title, profileImageUrl }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  const handleLogOut = async (event) => {
    event.preventDefault();
    await logOut(); // Assuming logOut is an async function
    navigate('/'); // Navigate to homepage after logging out
  };

  const goToMyProfile = () => {
    const userId = auth.currentUser.uid; // Get current user ID from auth
    navigate(`/user/${userId}`); // Navigate to the user's profile page
  };

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const goHome = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    navigate(`/`);
  };

  // if i wanna add a search bar here

  // const [searchTerm, setSearchTerm] = useState('');
  // const handleSearch = (event) => {
  //   event.preventDefault(); // Prevent default form submission behavior
  //   if (searchTerm.trim()) {
  //     navigate(`/channel/${searchTerm.trim()}`);
  //   }
  // };

  return (
    <div className="top-bar">
      <div className="top-bar-title" onClick={goHome}>{title}</div>
      {/* Navigation Links */}

      {/* for the searchbar */}

      {/* <div className="searchbar">
        <br></br>
        <form onSubmit={handleSearch}>
          <input
            className="input"
            type="text"
            placeholder="Search for a channel"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button type="submit">Search</button>
        </form>
      </div> */}

      <div className="right-side">
        <div className="navigation-links">
          {/* <button onClick={() => navigate('/lab')}>Lab</button> */}
          {/* <button onClick={() => navigate('/edu')}>Edu</button> */}
          <button onClick={() => navigate('/videos')}>Videos</button>
          <button onClick={() => navigate('/channels')}>Channels</button>
          {/* <button onClick={() => navigate('/users')}>Users</button> */}
          <button onClick={() => navigate('/topics')}>Topics</button>
        </div>


        <div className="top-bar-profile" onClick={toggleDropdown}>
          {auth.currentUser ? (
            <>
              {profileImageUrl && <img src={profileImageUrl} alt="Profile" />}
              {showDropdown && (
                <div className="dropdown-menu">
                  <button onClick={goToMyProfile}>My Profile</button>
                  <button onClick={handleLogOut}>Log Out</button>
                </div>
              )}
            </>
          ) : (
            <button className="log-in-button" onClick={() => navigate('/login')}>Log In</button>
          )}
        </div>

      </div>

    </div>
  );
};

export default TopBar;
