import { signInWithGoogle, auth } from "../firebase";
import './Login.css';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
  // console.log(auth?.currentUser?.photoURL);
  const navigate = useNavigate();

  return (
    <div className="App">
      <div className="login-page">
        <div className="title">
          S-TIER STUFF
        </div>

        <div className="body">
          <div className="description">
            Learn from top educational YouTube videos and share your reviews.
          </div>

          <div className="blurb-container">
            <div className="blurb">
              Welcome! <br /><br />
              We believe YouTube is an incredibly valuable resource for learning just about anything. However, finding these resources can be challenging amidst the addictive nature of social media. That's why we created this site—to focus on enhancing the educational side of YouTube. <br /><br />
              Currently, you can search for videos to generate comprehension questions and transcript summaries or rate and review different YouTube videos and channels. <br /><br />
              We're excited to add many more features! If you have feedback or suggestions, <a className="nav-link" href="mailto:stierstuff@gmail.com">please email us</a>. We'd love to hear from you :)
            </div>
          </div>

          <div className="buttons">
            <button className="google-sign-in-btn" onClick={signInWithGoogle}>
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1200px-Google_%22G%22_logo.svg.png" alt="Google logo" />
              <div className="signInText">
                <span>Sign in with Google</span>
              </div>
            </button>

            <button className="guest-btn" onClick={() => navigate('/home')}>
              <span>Continue as Guest</span>
            </button>

          </div>


          <div className="terms-privacy">
            <br></br>
            By clicking the sign in button, you agree to the <a href="terms">Terms</a> and <a href="privacy">Privacy Policy</a>.
          </div>

        </div>
      </div>

    </div>
  );
};

export default Login;