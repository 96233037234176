import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import TopBar from './TopBar';
import './UserPage.css'; // Ensure CSS styles are updated accordingly
import Footer from './Footer';
import Review from './Review';

const UserPage = () => {
  const { userName } = useParams();
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showEditBio, setShowEditBio] = useState(false);
  const [editBio, setEditBio] = useState(""); // To store the edited bio

  useEffect(() => {
    const fetchUserData = async () => {
      const userDocRef = doc(db, "users", userName);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        setUserData(userDoc.data());
        setEditBio(userDoc.data().bio); // Initialize editBio with the current bio
      } else {
        setUserData(null);
      }
      setIsLoading(false);
    };

    fetchUserData();
  }, [userName]);

  const handleEditBio = () => {
    setShowEditBio(true); // Show the input field for editing bio
  };

  const handleBioSubmit = async () => {
    if (auth.currentUser && userName === auth.currentUser.uid) {
      await updateDoc(doc(db, "users", userName), {
        bio: editBio,
      });
      setShowEditBio(false); // Hide the input field after submitting
      // Optionally, fetch user data again to update the UI
      setUserData(prevState => ({
        ...prevState,
        bio: editBio,
      }));
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="userpage">
      <TopBar
        title="S-TIER STUFF"
        profileImageUrl={auth?.currentUser?.photoURL ? auth?.currentUser?.photoURL : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
      />

      {userData ? (
        <div className="user-all">
          <div className="user-content">
            <div className="user-details">
              <div className="profile-and-name">
                <div className="user-name">{userData.displayName}</div>
                <img src={userData.photoURL || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt="User profile" />
              </div>
              <div className="user-subtitle">user</div>
              <div className="bio">{userData.bio || "No bio available."}</div>
              {auth.currentUser && userName === auth.currentUser.uid && !showEditBio && (
                <button onClick={handleEditBio}>Edit Bio</button>
              )}
              {showEditBio && (
                <div className="review-popup" style={{ padding: '20px', maxWidth: '600px' }}>
                  <textarea
                    className="edit-bio-textarea"
                    value={editBio}
                    onChange={(e) => setEditBio(e.target.value)}
                    style={{ margin: '10px 0', padding: '10px', height: '100px' }}
                  />
                  <button className="submit-bio-button" onClick={handleBioSubmit} style={{ marginTop: '10px' }}>
                    Submit
                  </button>
                </div>
              )}
            </div>

            <div className="user-stats">
              <br></br>
              <div>
                <p>Number of Ratings: {userData.numRated}</p>
              </div>
              <br></br>

              <div className="ratings-distribution">
                {
                  ['S', 'A', 'B', 'C', 'D', 'F'].map((key) => {
                    const totalRatings = Object.values(userData?.ratingsCount || {}).reduce((acc, value) => acc + value, 0);
                    const ratingValue = userData?.ratingsCount[key] || 0;
                    const percentage = totalRatings > 0 ? (ratingValue / totalRatings) * 100 : 0;

                    return (
                      <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <span style={{ width: '20px', marginRight: '10px' }}>{key}</span>
                        <div style={{ background: '#e0e0e0', width: '100%', marginRight: '10px', display: 'flex', height: '20px' }}>
                          <div style={{ background: '#6DD3CE', width: `${percentage + 1}%` }}></div>
                        </div>
                        <span>{ratingValue}</span>
                      </div>
                    );
                  })
                }
              </div>

            </div>
          </div>

          <div className="user-reviews">
            <h2>Channel Reviews</h2>
            {userData.reviews && userData.reviews.length > 0 ? (
              <ul className="reviews-list">
                {userData.reviews.map((review, index) => (
                  <Review
                    key={index}
                    rating={review.rating}
                    text={review.text}
                    channel={review.channelName}
                    user={userData.displayName}
                    linkTo={`/channel/${review.channelName}`}
                  />
                ))}
              </ul>
            ) : (
              <p>No reviews yet.</p>
            )}
          </div>


        </div>
      ) : (
        <div className="user-not-found">
          <h1>User not found</h1>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default UserPage;
