import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchCommentsAndAnalyze, fetchTranscriptAndAnalyze, getVideoDetails } from '../api/commentsllm3';
import TopBar from './TopBar';
import Footer from './Footer';
import { db, auth } from '../firebase';
import { doc, getDoc, setDoc, serverTimestamp, updateDoc, collection, addDoc } from 'firebase/firestore';
import './VideoPage.css';
import Review from './Review';
import { useNavigate } from 'react-router-dom';

const VideoPage = () => {
  const { videoId } = useParams();
  const [videoData, setVideoData] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAnswers, setShowAnswers] = useState([]);
  const [showTranscriptSummary, setShowTranscriptSummary] = useState(true);
  const [showTranscript, setShowTranscript] = useState(false);
  const [showCommentAnalysis, setShowCommentAnalysis] = useState(false);
  const [showReviewPopup, setShowReviewPopup] = useState(false);
  const [rating, setRating] = useState('');
  const [reviewText, setReviewText] = useState('');

  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const checkVideoInDatabase = async () => {
      setIsLoading(true);
      try {
        const videoDocRef = doc(db, 'videos', videoId);
        const videoDoc = await getDoc(videoDocRef);

        if (videoDoc.exists()) {
          setVideoData(videoDoc.data());
        }
      } catch (error) {
        console.error('Error checking video in database:', error);
      } finally {
        setIsLoading(false);
      }
    };

    window.scrollTo(0, 0);


    checkVideoInDatabase();
  }, [videoId]);

  const handleAnalyzeVideo = async () => {
    setIsAnalyzing(true);
    try {
      const logCollectionRef = collection(db, "log");
      await addDoc(logCollectionRef, {
        action: "tried analyzing video",
        video: videoId,
        user: auth.currentUser.uid,
        timestamp: serverTimestamp(),
      });

      // Check if the video is already in the database
      const videoDocRef = doc(db, 'videos', videoId);
      const videoDoc = await getDoc(videoDocRef);

      if (videoDoc.exists()) {
        throw new Error('Video is already in the database');
      }

      const [analysis, transcript, videoInfo] = await Promise.all([
        fetchCommentsAndAnalyze(videoId),
        fetchTranscriptAndAnalyze(videoId),
        getVideoDetails(videoId),
      ]);

      await setDoc(videoDocRef, {
        ...analysis,
        transcriptSummary: transcript.summary,
        transcript: transcript.text,
        compQuestions: transcript.questions,
        compAnswers: transcript.answers,
        timestamp: serverTimestamp(),
        title: videoInfo.title,
        reviews: [],
        numberOfReviews: 0,
        userAdded: auth.currentUser.uid,
        ratingsCount: {
          S: 0,
          A: 0,
          B: 0,
          C: 0,
          D: 0,
          F: 0,
        },
        channel: videoInfo.channel,
        description: videoInfo.description,
        thumbnail: videoInfo.thumbnail,
        viewCount: videoInfo.viewCount,
        likeCount: videoInfo.likeCount,
        commentCount: videoInfo.commentCount,
      });

      setVideoData({
        ...analysis,
        transcriptSummary: transcript.summary,
        transcript: transcript.text,
        compQuestions: transcript.questions,
        compAnswers: transcript.answers,
        title: videoInfo.title,
        reviews: [],
        numberOfReviews: 0,
        ratingsCount: {
          S: 0,
          A: 0,
          B: 0,
          C: 0,
          D: 0,
          F: 0,
        },
        channel: videoInfo.channel,
        description: videoInfo.description,
        thumbnail: videoInfo.thumbnail,
        viewCount: videoInfo.viewCount,
        likeCount: videoInfo.likeCount,
        commentCount: videoInfo.commentCount,
      });
    } catch (error) {
      console.error('Error analyzing video:', error);
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleAddReview = () => {
    // Check if the user already reviewed this channel
    const existingReview = videoData.reviews.find(review => review.userId === auth.currentUser.uid);
    if (existingReview) {
      // If there is an existing review, populate the form for editing
      setRating(existingReview.rating);
      setReviewText(existingReview.text);
    } else {
      // If no existing review, clear the form
      setRating('');
      setReviewText('');
    }
    setShowReviewPopup(true); // Show the popup for adding/editing a review
  };

  const submitReview = async () => {
    if (!rating || !reviewText) {
      alert("Please fill in all fields");
      return;
    }

    const logCollectionRef = collection(db, "log");
    await addDoc(logCollectionRef, {
      action: "tried submitting video review",
      video: videoId,
      user: auth.currentUser.uid,
      timestamp: serverTimestamp(),
    });

    const existingReviewIndex = videoData.reviews.findIndex(review => review.userId === auth.currentUser.uid);
    const isEdit = existingReviewIndex !== -1;
    const oldReviewRating = isEdit ? videoData.reviews[existingReviewIndex].rating : null;

    // Prepare the updated reviews array for the channel
    let updatedReviews = [...videoData.reviews];
    if (isEdit) {
      updatedReviews[existingReviewIndex] = { ...updatedReviews[existingReviewIndex], rating, text: reviewText };
    } else {
      updatedReviews.push({ userId: auth.currentUser.uid, rating, text: reviewText });
    }

    // Update the channel's ratingsCount
    let updatedVideoRatingsCount = { ...videoData.ratingsCount, [rating]: (videoData.ratingsCount[rating] || 0) + 1 };
    if (isEdit && oldReviewRating) {
      updatedVideoRatingsCount[oldReviewRating] = Math.max(0, (updatedVideoRatingsCount[oldReviewRating] || 0) - 1);
    }

    // Update channel document in Firestore
    const videoDocRef = doc(db, "videos", videoId);
    await updateDoc(videoDocRef, {
      reviews: updatedReviews,
      ratingsCount: updatedVideoRatingsCount,
      numberOfReviews: updatedReviews.length,
    });

    // const userDocRef = doc(db, "users", auth.currentUser.uid);
    // const userDocSnap = await getDoc(userDocRef);

    // let userData = userDocSnap.data();
    // let userRatingsCount = userData.ratingsCount || { S: 0, A: 0, B: 0, C: 0, D: 0, F: 0 };
    // let userReviews = userData.reviews || [];
    // let userNumRated = userData.numRated || 0;

    // userRatingsCount[rating] = (userRatingsCount[rating] || 0) + 1;
    // userNumRated = userNumRated + 1;
    // if (isEdit && oldReviewRating) {
    //   userRatingsCount[oldReviewRating] = Math.max(0, (userRatingsCount[oldReviewRating] || 0) - 1);
    //   userNumRated = userNumRated - 1;
    // }

    // Handle ratingsCount logic as before

    // Check if there's an existing review for this channel by this user
    // const existingUserReviewIndex = userReviews.findIndex(review => review.channelName === channelName.toLowerCase());

    // Prepare the review object
    // const reviewToUpdateOrAdd = {
    //   channelName: channelName.toLowerCase(),
    //   rating,
    //   text: reviewText,
    // };

    // // Update or add the review
    // if (isEdit) {
    //   userReviews[existingUserReviewIndex] = reviewToUpdateOrAdd;
    // } else {
    //   userReviews.push(reviewToUpdateOrAdd);
    // }

    // Update Firestore document for user
    // await updateDoc(userDocRef, {
    //   ratingsCount: userRatingsCount,
    //   reviews: userReviews,
    //   numRated: userNumRated
    // });

    // maybe do this later, video review doc. also ^^

    // const reviewCollectionRef = collection(db, "reviews");
    // await addDoc(reviewCollectionRef, {
    //   channel: channelName,
    //   user: auth.currentUser.uid,
    //   text: reviewText,
    //   rating,
    //   timestamp: serverTimestamp(),
    // });

    // Update local state and close popup
    setVideoData(prevState => ({
      ...prevState,
      reviews: updatedReviews,
      ratingsCount: updatedVideoRatingsCount,
      numberOfReviews: updatedReviews.length
    }));

    closeReviewPopup();
  };

  const findMyReview = () => {
    try {
      // Find the review made by the current user
      const myReview = videoData.reviews.find(review => review.userId === auth.currentUser.uid);

      if (myReview) {
        // Review found, return the rating and text
        return { rating: myReview.rating, text: myReview.text };
      } else {
        // No review found
        return { rating: null, text: "You haven't rated this channel yet." };
      }
    }
    catch (err) {
      return;
    }
  };

  // Close the review popup
  const closeReviewPopup = () => {
    setShowReviewPopup(false);
  };

  const toggleAnswerVisibility = (index) => {
    const updatedShowAnswers = [...showAnswers];
    updatedShowAnswers[index] = !updatedShowAnswers[index];
    setShowAnswers(updatedShowAnswers);
  };

  const toggleTranscriptSummaryVisibility = () => {
    setShowTranscriptSummary(!showTranscriptSummary);
  };

  const toggleTranscriptVisibility = () => {
    setShowTranscript(!showTranscript);
  };

  const toggleCommentAnalysisVisibility = () => {
    setShowCommentAnalysis(!showCommentAnalysis);
  }

  const formatSummary = (summary) => {
    return summary.replace(/<br><br>/g, '\n\n');
  };

  const TranscriptSummary = ({ summary }) => {
    const formattedSummary = formatSummary(summary);
  
    return (
      <div>
        {formattedSummary.split('\n\n').map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    );
  };


  return (
    <div className="video-page">
      <TopBar title="S-TIER STUFF" profileImageUrl={auth?.currentUser?.photoURL} />
      <div className='page-body'>

        {isLoading ? (
          <p>Loading video data...</p>
        ) : videoData ? (
          <div className="results">
            <div className="top-part">
              <div className="video-embed">
                <iframe
                  src={`https://www.youtube.com/embed/${videoId}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="video-info">
                {/* <h3>Video Details</h3> */}
                <div className="video-title">{videoData.title}</div>
                <div className="video-channel"><Link to={`/channel/${videoData.channel}`} className="nav-link">{videoData.channel}</Link></div>
                {/* <div><strong>Number of Reviews:</strong> {videoData.numberOfReviews}</div>
              <button className="rate-button">
                {'Rate/Review Video'}
              </button> */}

                <div className="num-reviews">
                  <p>Number of Reviews: {videoData.numberOfReviews}</p>
                </div>

                <div className="ratings-distribution">
                  {
                    ['S', 'A', 'B', 'C', 'D', 'F'].map((key) => {
                      const totalRatings = Object.values(videoData?.ratingsCount || {}).reduce((acc, value) => acc + value, 0);
                      const ratingValue = videoData?.ratingsCount[key] || 0;
                      const percentage = totalRatings > 0 ? (ratingValue / totalRatings) * 100 : 0;

                      return (
                        <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                          <span style={{ width: '20px', marginRight: '10px' }}>{key}</span>
                          <div style={{ background: '#e0e0e0', width: '100%', marginRight: '10px', display: 'flex', height: '20px' }}>
                            <div style={{ background: '#225560', width: `${percentage + 1}%` }}></div>
                          </div>
                          <span>{ratingValue}</span>
                        </div>
                      );
                    })
                  }
                </div>

                <div>
                  {auth.currentUser ? (
                    (() => {
                      const review = findMyReview();
                      return review.rating ? (
                        <div className="num-reviews">
                          <p>Your rating: {review.rating}</p>
                          <button onClick={handleAddReview}>Edit Review</button>
                        </div>
                      ) : (
                        <div>
                          <br />
                          <button onClick={handleAddReview}>Add Review</button>
                        </div>
                      );
                    })()
                  ) : (
                    <div>
                      <br />
                      <button onClick={() => navigate('/login')}>Log in to review!</button>
                    </div>
                  )}
                </div>

              </div>

            </div>

            <div className="transcript-result">
              <h3>Comprehension Questions</h3>
              <div className="comp-questions">

                {videoData.compQuestions ? videoData.compQuestions.map((question, index) => (
                  <div key={index} className="qa-pair">
                    <div className="question-row">
                      <div className="question">
                        <strong>{index + 1}.</strong> {question}
                      </div>
                      <button class="answer-button" onClick={() => toggleAnswerVisibility(index)}>
                        {showAnswers[index] ? 'Hide Answer' : 'Show Answer'}
                      </button>
                    </div>

                    {showAnswers[index] && (
                      <div className="answer">
                        <strong>Answer:</strong> {videoData.compAnswers[index]} <br></br><br></br>
                      </div>
                    )}
                  </div>
                )) : "No questions available"}
              </div>

              <button class="other-button" onClick={toggleTranscriptSummaryVisibility}>
                {showTranscriptSummary ? 'Hide Transcript Summary' : 'Show Transcript Summary'}
              </button>
              {showTranscriptSummary && (

                <div className="summary">
                  <h3>Transcript Summary</h3>

                  
                  {<TranscriptSummary summary={videoData.transcriptSummary} /> ?? "No transcript summary available"}</div>
              )}

              <button class="other-button" onClick={toggleTranscriptVisibility}>
                {showTranscript ? 'Hide Transcript' : 'Show Transcript'}
              </button>
              {showTranscript && (
                <div className="summary">
                  <h3>Transcript</h3>
                  {videoData.transcript ?? "No transcript available"}</div>
              )}

              <button class="other-button" onClick={toggleCommentAnalysisVisibility}>
                {showCommentAnalysis ? 'Hide Comment Analysis' : 'Show Comment Analysis'}
              </button>
              {showCommentAnalysis && (
                <div className="summary">
                  <h3>Comment Analysis</h3>
                  <div>Rating: {videoData.rating ?? "No rating available"}</div> <br></br>
                  <div>Summary: {videoData.summary ?? "No summary available"}</div>
                </div>
              )}

            </div>

            <h2>Reviews</h2>

            <div className="video-reviews-section">
              {videoData.reviews.length > 0 ? (
                <ul className="reviews-list">
                  {videoData.reviews.map((review, index) => (
                    <Review
                      key={index}
                      rating={review.rating}
                      text={review.text}
                      channel={videoData.title} // Assuming channelName is available in this scope
                      user={review.displayName || 'Someone'}
                      linkTo={`/user/${review.userId}`}
                    />
                  ))}
                </ul>
              ) : (
                <p>No reviews yet.</p>
              )}
            </div>

            <div className="problems-report">
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSc6t-qq1h04UOpKNrfqRVjIAKkYssxVf-hKO5BmS8kQ8FvVaQ/viewform" target="_blank" className="nav-link">Issues or Feedback?</a>
            </div>

          </div>
        ) : (
          <div className="results">
            <div className="video-embed">
              <iframe
                width="600"
                height="400"
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            <div className="analyze-section">
              <p>This video has not been analyzed yet.</p>
              {auth.currentUser ? (
                <button onClick={handleAnalyzeVideo} disabled={isAnalyzing}>
                  {isAnalyzing ? 'Analyzing...' : 'Analyze Video'}
                </button>
              ) : (
                <button onClick={() => navigate('/login')}>Log in to analyze!</button>
              )}
            </div>
          </div>
        )}
      </div>

      {showReviewPopup && (
        <div className="review-popup">
          <h3>Write a Review</h3>

          <div className="rating-buttons">
            {["S", "A", "B", "C", "D", "F"].map((rate) => (
              <button
                key={rate}
                className={`rating-button ${rating === rate ? 'selected' : ''}`}
                onClick={() => setRating(rate)}
              >
                {rate}
              </button>
            ))}
          </div>

          <textarea
            placeholder="Write your review here"
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
          />
          <button onClick={submitReview}>Submit Review</button>
          <button onClick={closeReviewPopup}>Cancel</button>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default VideoPage;